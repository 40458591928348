<template>
  <div class="left-side flex-1 flex items-center">
    <div class="left-side flex items-center ml-2 mb-1">
      <template v-if="hasAnimation">
        <div
          class="filter-dropshadow py-1 px-4 cursor-pointer rounded bg-gray-600 bg-opacity-50 hover:bg-opacity-100 hover:bg-gray-700"
          :class="{ 'bg-gray-800 text-white bg-opacity-100': showPlaybackMenu }"
          @click.prevent.stop="$emit('show-playback')"
        >
          {{ activeAnimationName }}
        </div>
        <button
          class="filter-dropshadow relative inline-flex items-center px-2 py-2 ml-2 rounded-full text-sm leading-5 font-medium text-white hover:bg-gray-800 focus:z-10 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150"
          @click.prevent="togglePlayAnimation"
        >
          <fa-icon
            v-if="animationIsPlaying"
            icon="pause"
          />
          <fa-icon
            v-else
            icon="play"
          />
        </button>
      </template>
    </div>

    <ProgressBar
      v-if="hasAnimation"
      :progress-pc="progressPc"
      :duration="maxAnimationTime"
      @jump-to-time="jumpToTime"
    />
  </div>
</template>
<script>
const ProgressBar = () => import('./ProgressBar')
export default {
  name: 'ViewerPlaybackControls',

  components: { ProgressBar },

  props: {
    activeAnimation: {
      type: Object,
      required: false
    },

    animationIsPlaying: {
      type: Boolean,
      default: false
    },

    animationTime: {
      type: Number,
      default: 0
    },

    showPlaybackMenu: {
      type: Boolean,
      default: false
    }
  },

  computed: {

    progressPc () {
      if (this.maxAnimationTime === 0) return 0
      return (this.currentAnimationTime / this.maxAnimationTime) * 100
    },

    hasAnimation () {
      return true
    },

    activeAnimationName () {
      if (this.activeAnimation !== null) return this.activeAnimation.name
      return '(None)'
    },

    maxAnimationTime () {
      if (this.activeAnimation === null) return 0
      return this.activeAnimation.duration
    },

    currentAnimationTime: {
      get () {
        return (this.animationTime % this.maxAnimationTime)
      },
      set (val) {
        this.$emit('scrub-animation', this.activeAnimation.name, val)
      }
    }
  },

  methods: {

    jumpToTime (time) {
      this.currentAnimationTime = time
    },

    togglePlayAnimation () {
      this.$emit('toggle-play-animation', this.activeAnimation.name)
    }
  }
}
</script>
